import Slider from "../utils/slider";

window.onload = () => {
    const sliderContent = document.getElementById("carouselConstructionTypes");

    if (sliderContent) {
        new Slider({
            wrapper: sliderContent,
        });
    }
};
