import axios from "axios";
import Modal from "bootstrap/js/dist/modal";
import Slider from "../utils/slider";

(function () {
    const toggleMenu = document.getElementById("toggle-menu"),
        productTypeList = document.getElementById("product-type-list"),
        productList = document.getElementById("product-list"),
        loader = document.getElementById("loader"),
        productDetailsModalWrapper = document.getElementById(
            "product-details-modal"
        ),
        productDetals = document.getElementById("product-details");

    init();

    function init() {
        getCurrentProductTypeProducts();
        eventListeners();
    }

    /**
     * Event listeners
     */
    function eventListeners() {
        if (toggleMenu && productTypeList) {
            toggleMenu.addEventListener("click", () => {
                if (toggleMenu.classList.contains("active")) {
                    productTypeList.classList.add("hide");
                    toggleMenu.classList.remove("active");
                } else {
                    productTypeList.classList.remove("hide");
                    toggleMenu.classList.add("active");
                }
            });
        }

        document.body.addEventListener("click", async (e) => {
            const target = e.target;

            if (target.classList.contains("js-product-type-list-item")) {
                resetProductData();

                currentTypeId = target.getAttribute("data-id");

                const prevActive = document.querySelector(
                    ".js-product-type-list-item.active"
                );
                if (prevActive) prevActive.classList.remove("active");
                target.classList.add("active");

                currentPage = 1;

                window.history.pushState({}, "", `/galerija/${currentTypeId}`);

                getCurrentProductTypeProducts(target);
            }

            if (
                target.classList.contains("js-product-list-item") ||
                target.closest(".js-product-list-item")
            ) {
                setModalProductData(target, "js-product-list-item", true);
            }

            if (
                target.classList.contains("js-slider-item") ||
                target.parentNode.classList.contains("js-slider-item")
            ) {
                setModalProductData(target, "js-slider-item");
            }
        });
    }

    /**
     * Show list of products
     * @param data - list of products
     */
    function showProducts(data) {
        loader.classList.add("hide");
        if (productList) productList.innerHTML = data;
    }

    /**
     * Open product detail modal
     * @param target - clicked element
     * @param initModal - should modal be initilized or not
     */
    async function setModalProductData(target, className, initModal = false) {
        const targetElement = target.classList.contains(className)
            ? target
            : target.closest(`.${className}`);

        const id = targetElement.getAttribute("data-id");
        if (id) {
            if (productDetals) productDetals.innerHTML = "";
            if (productDetailsModalWrapper)
                productDetailsModalWrapper.classList.add("data-out");

            if (initModal) {
                const myModal = new Modal(productDetailsModalWrapper, {});

                myModal.show();
            }

            const res = await axios
                .get(`/objekat/${id}`, {
                    params: {
                        productTypeId: currentTypeId,
                    },
                })
                .catch(() => {
                    showProductDetails(
                        `<div class="alert alert-danger" role="alert">Greska, ne postoji trazeni objekat!</div>`
                    );
                });

            if (res) {
                showProductDetails(res.data);
            }
        }
    }

    /**
     * Remove current product list and show laoder
     */
    function resetProductData() {
        if (productList) productList.innerHTML = "";
        if (loader && loader.classList.contains("hide")) {
            loader.classList.remove("hide");
        }
    }

    /**
     * Show product details, init slider
     * @param data - product detail data
     */
    function showProductDetails(data) {
        const productDetals = document.getElementById("product-details");

        if (productDetals) productDetals.innerHTML = data;
        if (productDetailsModalWrapper)
            productDetailsModalWrapper.classList.remove("data-out");

        const sliderContent = document.getElementById("carousel-products");

        if (sliderContent) {
            setTimeout(() => {
                new Slider({
                    wrapper: sliderContent,
                });
            }, 500);
        }
    }

    /**
     * Send ajax to get current product type data
     */
    async function getCurrentProductTypeProducts() {
        if (currentTypeId) {
            const res = await axios
                .get(`/tipovi-objekta/${currentTypeId}`, {
                    params: {
                        currentPage: 1,
                        currentPage,
                    },
                })
                .catch(() => {
                    showProducts(
                        `<li class="no-data col-12 mb-3">Nema objekata!</li>`
                    );
                });

            if (res) {
                showProducts(res.data);
            }
        }
    }
})();
