(() => {
    setTimeout(() => {
        const header = document.querySelector(".js-header");
        const headerHeight = header && header.clientHeight;
        const notStickyHeaderHeight = header && !header.classList.contains('sticky') ? headerHeight : 0;
        const contactUs = document.querySelector(".js-contact");
        const contactTopPosition =
        contactUs && contactUs.getBoundingClientRect().top;

        if (contactTopPosition) {
            window.scrollTo(0, contactTopPosition - notStickyHeaderHeight - 53);
        }
    }, 1001);
})();
