(() => {
    setTimeout(() => {
        const header = document.querySelector(".js-header");
        const headerHeight = header && header.clientHeight;
        const notStickyHeaderHeight = header && !header.classList.contains('sticky') ? headerHeight : 0;
        const whatWeAreDoing = document.querySelector(".js-cime-se-bavimo");
        const whatWeAreDoingTopPosition =
            whatWeAreDoing && whatWeAreDoing.offsetTop;

        if (whatWeAreDoingTopPosition) {
            window.scrollTo(0, whatWeAreDoingTopPosition - notStickyHeaderHeight - 53);
        }
    }, 1001);
})();

